import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import NormalPageBlock from "../components/normal-page-block";

class About extends Component {
    render() {
        return (
            <Layout
                headerElement={<NormalPageBlock siteTitle="DPAD Timeline" />}
                scrollStepInPx={50}
                delayInMs={2}
            >
                <SEO title="DPAD Timeline" />
                <section className="p-0">
                    <div className="container">
                        <div className="row justify-content-center">
                        <section id="page-content" className="sidebar-right">
                            <div className="container">
                                <div className="row">
                                    <div className="content col-lg-12">
                                        <div id="blog" className="single-post">
                                            <div className="post-item">
                                                <div className="post-item-wrap">
                                                    <div className="content col-lg-12">
                                                        <h2 className="text-center p-b-85">African Nova Scotian Decade for People of African Descent Timeline</h2>
                                                        <ul className="timeline">
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">December 23, 2013</div>
                                                                <h4 className="timeline-blocks w-100">United Nations General Assembly declared January 1, 2015 – December 31, 2024 the UN International Decade for People of African Descent</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">January 1, 2015</div>
                                                                <h4 className="timeline-blocks w-100">International Decade for People of African Descent begins</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">October 17 – 21, 2016</div>
                                                                <h4 className="timeline-blocks w-100x text-justify">United Nations Working Group of Experts visits Canada to talk with African Canadian community and government about issues that affect people of African descent living in Canada</h4>
                                                                <div className="timeline-blocks mt-3 mb-3 adjust-image">
                                                                    <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/un3.jpg" style={{width: 300}} />
                                                                </div>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">October 19, 2016</div>
                                                                <h4 className="timeline-blocks w-100">United Nations Working Group meets with the African Nova Scotian Community and Organizations at the Black Cultural Centre</h4>
                                                                <ul className="timeline-inline mt-3 mb-3">
                                                                    <li className="timeline-inline w-100">After this meeting over 30 ANS organizations decided to work collaboratively on issues that affect our community.</li>
                                                                    <li className="timeline-inline w-100">The African Nova Scotian Decade for People of African Descent Coalition (DPAD) was created and meets monthly</li>
                                                                </ul>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">January, 2017</div>
                                                                <h4 className="timeline-blocks w-100 text-justify">The African United Baptist Association (AUBA) in conjunction with Dalhousie Legal Aid called for an immediate ban on street checks and were supported by DPAD.</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">April 28, 2017</div>
                                                                <h4 className="timeline-blocks w-100">DPAD presents to the Deputy Ministers</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">August 16, 2017</div>
                                                                <h4 className="timeline-blocks w-100">United Nations releases: Report of the Working Group of Experts on People of African Descent on its mission to Canada.</h4>
                                                                <ul className="timeline-inline mt-3 mb-3">
                                                                    <li className="timeline-inline w-100">DPAD calls for Nova Scotia and Canada to recognize the UN Decade and address its recommendations</li>
                                                                </ul>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">September 2017</div>
                                                                <h4 className="timeline-blocks w-100">Creation of the Justice Strategy Working Group (JSWG)</h4>
                                                            </li>                                                            
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">November 2017</div>
                                                                <h4 className="timeline-blocks w-100">World Café Community Consultations</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">January 30, 2018</div>
                                                                <h4 className="timeline-blocks w-100 text-justify">Prime Minister Justin Trudeau announces that the government of Canada will officially recognize the UN decade. Commits $25 million to capacity building in the African Canadian Community</h4>
                                                                <div className="timeline-blocks mt-3 mb-3 adjust-image">
                                                                    <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/trudeau-speech.jpg" style={{width: 300}} />
                                                                </div>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">May 8, 2018</div>
                                                                <h4 className="timeline-blocks w-100">Nova Scotia officially recognizes the UN Decade and announces work on a provincial action plan</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">September 2017 – August 2018</div>
                                                                <h4 className="timeline-blocks w-100 text-justify">DPAD continues to work on the issue of street checks, begins to develop governance document and receives funding to hire a Program Coordinator, Office assistant and open an office.</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">May 30, 2018</div>
                                                                <h4 className="timeline-blocks w-100">DPAD meets with the Liberal Caucus</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">December 2018</div>
                                                                <h4 className="timeline-blocks w-100 text-justify">DPAD supports the Health Association of African Canadians’ (HAAC) work in the creation of a position to support the African Nova Scotian Health Strategy</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">December 2018</div>
                                                                <h4 className="timeline-blocks w-100">Creation of the Community Services Working Group (CSWG)</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">December 2018</div>
                                                                <h4 className="timeline-blocks w-100">DPAD forms the Health Strategy Working Group (HSWG)</h4>
                                                            </li>         
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">March 27, 2019</div>
                                                                <h4 className="timeline-blocks w-100 text-justify">Department of Justice places a moratorium on street checks but the Minister of Justice’s directive still permits illegal street checks to continue.</h4>
                                                            </li>                                                                                                                        
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">March 2019</div>
                                                                <h4 className="timeline-blocks w-100">Wortley Report on Street Checks data is released</h4>
                                                                <ul className="timeline-inline mt-3 mb-3">
                                                                    <li className="timeline-inline w-100">DPAD calls for an immediate ban on all street checks</li>
                                                                </ul>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">May 2019</div>
                                                                <h4 className="timeline-blocks w-100">DPAD asks NSHRC for independent legal review on street checks </h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">September 2019</div>
                                                                <h4 className="timeline-blocks w-100">DPAD presents to the United Nations Deputy High Commissioner on Human Rights on our work</h4>
                                                            </li>                                                             
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">September 2019</div>
                                                                <h4 className="timeline-blocks w-100">Nova Scotia’s Action Plan in Response to the International Decade for People of African Descent (Count Us In) is released</h4>
                                                                <div className="timeline-blocks mt-3 mb-3 adjust-image">
                                                                    <a href="https://ansa.novascotia.ca/sites/default/files/dpad-action-plan.pdf"><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/CountUsIn-f.jpg" style={{width: 300}} /></a>
                                                                </div>
                                                            </li> 
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">October 2019</div>
                                                                <h4 className="timeline-blocks w-100">Community Café updates</h4>
                                                            </li> 
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">October 21, 2019</div>
                                                                <h4 className="timeline-blocks w-100">Independent legal review deems street checks illegal</h4>
                                                            </li> 
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">October 21, 2019</div>
                                                                <h4 className="timeline-blocks w-100">Department of Justice bans streets checks</h4>
                                                            </li> 
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">December 10, 2019</div>
                                                                <h4 className="timeline-blocks w-100">ANSDPAD is awarded a Nova Scotia Human Rights Award</h4>
                                                            </li>  
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">March 2020</div>
                                                                <h4 className="timeline-blocks w-100">Part of planning committee for the National Black Canadians Summit. Partnership with the Michaëlle Jean Foundation</h4>
                                                            </li>
                                                            <li className="timeline-item">
                                                                <div className="timeline-icon">Present</div>
                                                                <h4 className="timeline-blocks w-100">DPAD Coalition continues to support community and address on-going issues that affect our communities</h4>
                                                                <ul className="timeline-inline mt-3 mb-3">
                                                                    <li className="timeline-inline w-100">Truro: Watching Deer while Black</li>
                                                                    <li className="timeline-inline w-100">Shelburne: Well and clean drinking water </li>
                                                                    <li className="timeline-inline w-100">Yarmouth: First celebration of African Heritage Month</li>
                                                                    <li className="timeline-inline w-100">Digby: Ujamma Fisheries </li>
                                                                    <li className="timeline-inline w-100">Ongoing issues in policing and justice</li>
                                                                </ul>
                                                            </li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                </section>
            </Layout>
        );
    }
}

export default About
